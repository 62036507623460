import {CoreMenu} from '@core/types';
import {ROLES} from '@core/enums/Roles.enum';
import {OPERATORS_BREAK_TIME_PATH, OPERATORS_LIST_PATH, OPERATORS_WORK_TIME_PATH} from '@control-panel/helpers/paths';

export const mainMenu: CoreMenu[] = [
  {
    id: 'main',
    translate: 'Menu.CRM',
    type: 'collapsible',
    icon: 'category',
    url: '/crm',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
  },
  {
    id: 'helpDesk',
    translate: 'Menu.HelpDesk',
    type: 'item',
    icon: 'add-notepad',
    url: '/service-desk',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
  },
  {
    id: 'callCenter',
    translate: 'Menu.CallCenter',
    type: 'item',
    icon: 'address-book',
    url: '/call-center',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]

  },
  {
    id: 'chatService',
    translate: 'Menu.ChatService',
    type: 'item',
    icon: 'notification',
    url: '/chat-service',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
  }
];

export const crmMenu: CoreMenu[] = [
  {
    id: 'home',
    translate: 'Menu.Statistics',
    type: 'item',
    icon: 'category',
    url: 'crm',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
  },
  {
    id: 'help',
    translate: 'Menu.Management',
    type: 'collapsible',
    icon: 'rescue',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT],
    children: [
      {
        id: 'users',
        translate: 'Menu.Users',
        type: 'item',
        icon: 'profile-user',
        url: 'crm/users',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      {
        id: 'operators',
        translate: 'Menu.Operators',
        type: 'item',
        icon: 'people',
        url: '',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT],
        children: [
          {
            id: 'operators',
            translate: 'Menu.List',
            type: 'item',
            url: `crm/${OPERATORS_LIST_PATH}`,
          },
          {
            id: 'operators',
            translate: 'Menu.WorkTimes',
            type: 'item',
            url: `crm/${OPERATORS_WORK_TIME_PATH}`,
          },
          {
            id: 'operators',
            translate: 'Menu.BreakTimes',
            type: 'item',
            url: `crm/${OPERATORS_BREAK_TIME_PATH}`,
          },
          {
            id: 'operators-bind',
            translate: 'Actions.Buttons.WorkBind',
            type: 'item',
            url: `crm/operators-bind`,
          },
        ]
      },
      {
        id: 'roles',
        translate: 'Menu.Roles',
        type: 'item',
        icon: 'user-edit',
        url: 'crm/roles',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      {
        id: 'sip',
        translate: 'Menu.SipNumbers',
        type: 'item',
        icon: 'simcard-2',
        url: 'crm/sip-numbers',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      {
        id: 'video-stream-monitoring',
        translate: 'Menu.VideoStreamMonitoring',
        type: 'item',
        icon: 'element-2',
        url: 'crm/video-stream-monitoring',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      // {
      //   id: 'users-monitoring',
      //   translate: 'Menu.UserMonitoring',
      //   type: 'item',
      //   icon: 'monitor-mobile',
      //   url: 'crm/users-monitoring',
      //   role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      // },
      {
        id: 'bot-report',
        translate: 'Menu.BotReport',
        type: 'item',
        url: 'crm/bot-report',
        icon: 'chart-simple-3',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      {
        id: 'cas-rele-audit',
        translate: 'Menu.ReleAudit',
        type: 'item',
        url: 'crm/cas-rele-audit',
        icon: 'chart-simple',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      {
        id: 'phone-change-audit',
        translate: 'Menu.PhoneChangeAudit',
        type: 'item',
        url: 'crm/phone-change-audit',
        icon: 'chart-simple-2',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      {
        id: 'block-contacts',
        translate: 'Menu.BlockContacts',
        type: 'item',
        icon: 'information',
        url: '',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN],
        children: [
          {
            id: 'block-contacts',
            translate: 'Menu.List',
            type: 'item',
            url: 'crm/block-contacts',
          },
          {
            id: 'call-history',
            translate: 'Components.CallCenter.TableHeader.TableHistory',
            type: 'item',
            url: 'crm/call-history',
          },
        ]
      },
      {
        id: 'vip-customers',
        translate: 'Menu.VIPCustomers',
        type: 'item',
        icon: 'award',
        url: 'crm/vip-customers',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
      },
      {
        id: 'settings',
        translate: 'Settings.Name',
        type: 'item',
        icon: 'setting-2',
        url: 'crm/call-time',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT],
        children: [
          {
            id: 'coato-access',
            translate: 'Menu.CoatoSettings',
            type: 'item',
            url: `crm/coato-permits`,
          },
          {
            id: 'call-time',
            translate: `Menu.CallTime`,
            type: 'item',
            url: `crm/call-time`,
          },
        ]
      },
    ]
  },

  // {
  //   id: 'geo',
  //   translate: 'Menu.GeoMap',
  //   type: 'collapsible',
  //   icon: 'rescue',
  //   exactMatch: true,
  //   role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT],
  //   children: [
  //     {
  //       id: 'geo-map',
  //       translate: 'Menu.GeoMap',
  //       type: 'item',
  //       icon: 'map',
  //       url: 'crm/geo-map',
  //       role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
  //     },
  //     {
  //       id: 'geo-map-setting',
  //       translate: 'Menu.GeoMapSetting',
  //       type: 'item',
  //       icon: 'setting',
  //       url: 'crm/geo-map-setting',
  //       role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT]
  //     }
  //   ],
  // },
  // {
  //   id: 'notifications',
  //   translate: 'Bildirishnomalar',
  //   type: 'collapsible',
  //   icon: 'notification-on',
  //   role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN],
  //   children: [
  //     // {
  //     //   id: 'ntf',
  //     //   translate: 'Menu.SendNotificatin',
  //     //   type: 'item',
  //     //   icon: 'setting',
  //     //   url: 'call-center/notifications',
  //     //   role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
  //     // }
  //   ]
  // }
];

export const helpDeskMenu: CoreMenu[] = [
  {
    id: 'home',
    translate: 'Menu.Main',
    type: 'item',
    icon: 'category',
    url: 'service-desk',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
  },
  {
    id: 'ticket-dashboard',
    translate: 'Dashboard.Statistics',
    type: 'item',
    icon: 'questionnaire-tablet',
    url: 'service-desk/dashboard',
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]
  },
  {
    id: 'home',
    translate: 'Menu.Spravochnik.Title',
    type: 'collapsible',
    icon: 'book',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER],
    children: [
      {
        id: 'ticket-types',
        translate: 'Menu.Spravochnik.Children.TicketTypes',
        type: 'item',
        icon: 'ranking',
        url: 'service-desk/ticket-types',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]
      },
      {
        id: 'ticket-statuses',
        translate: 'Menu.Spravochnik.Children.TicketStatuses',
        type: 'item',
        icon: 'badge',
        url: 'service-desk/ticket-statuses',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]
      },
      {
        id: 'ticket-priorities',
        translate: 'Menu.Spravochnik.Children.TicketPriorities',
        type: 'item',
        icon: 'graph-up',
        url: 'service-desk/ticket-priorities',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER]
      }
    ]
  },
  {
    id: 'tickets-list',
    translate: 'Menu.Tickets',
    type: 'item',
    icon: 'note-2',
    url: 'service-desk/tickets-list',
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.OPERATOR, ROLES.PERFORMER, ROLES.AGENT]
  }
];

export const callCenterMenu: CoreMenu[] = [
  {
    id: 'home',
    translate: 'Menu.Main',
    type: 'item',
    icon: 'category',
    url: 'call-center',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
  },
  {
    id: 'home',
    translate: 'Menu.Calls',
    type: 'collapsible',
    icon: 'address-book',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER],
    children: [
      {
        id: 'queue-calls',
        translate: 'Components.CallCenter.TableHeader.QueueCalls',
        type: 'item',
        icon: 'profile-user',
        url: 'call-center/queue-calls',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      },
      {
        id: 'live-calls',
        translate: 'Menu.LiveCalls',
        type: 'item',
        icon: 'call',
        url: `call-center/live-calls`,
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN],
        children: [
          {
            id: 'live-calls',
            translate: `Menu.List`,
            type: 'item',
            url: `call-center/live-calls`,
          },
          {
            id: 'live-stats',
            translate: `Menu.LiveStats`,
            type: 'item',
            url: `call-center/live-stats`,
          },
          {
            id: 'delete-channels',
            translate: `Menu.DeleteChannels`,
            type: 'item',
            url: `call-center/delete-channels`,
          },
        ]
      },
      {
        id: 'call-history',
        translate: 'Menu.CallsHistory',
        type: 'item',
        icon: 'time',
        url: 'call-center/call-history',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
      },
      {
        id: 'incidents',
        translate: 'Menu.Incidents',
        type: 'item',
        icon: 'directbox-default',
        url: 'call-center/incidents',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      },
      {
        id: 'prefix-call',
        translate: 'Menu.Prefiks',
        type: 'item',
        icon: 'fasten',
        url: 'call-center/prefix-call',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      },
      {
        id: 'spy-report',
        translate: 'Menu.Spy',
        type: 'item',
        icon: 'ghost',
        url: 'call-center/spy-report',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      },
      {
        id: 'not-answered-call',
        translate: 'Menu.NotAnsweredCall',
        type: 'item',
        icon: 'tablet-down',
        url: 'call-center/not-answered-call',
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN]
      },
      {
        id: 'report',
        translate: 'Menu.Report',
        type: 'item',
        icon: 'chart-line-down',
        url: `call-center/operator-report`,
        role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN],
        children: [
          {
            id: 'operator-report',
            translate: 'Reports.Operator',
            type: 'item',
            url: 'call-center/operator-report',
          },
          {
            id: 'consumer-report',
            translate: `Menu.Consumers`,
            type: 'item',
            url: `call-center/consumer-report`,
          },
        ]
      }
    ]
  },
  {
    id: 'call',
    translate: 'Menu.Consumers',
    type: 'item',
    icon: 'people',
    exactMatch: true,
    url: 'call-center/call',
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
  },
];

export const chatServiceMenu: CoreMenu[] = [
  {
    id: 'home',
    translate: 'Menu.Main',
    type: 'item',
    icon: 'category',
    url: 'chat-service',
    exactMatch: true,
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
  },
  {
    id: 'consumers',
    translate: 'Menu.Consumers',
    type: 'item',
    icon: 'people',
    exactMatch: true,
    url: 'chat-service/consumers',
    role: [ROLES.DEPARTMENT_HEAD, ROLES.ADMIN, ROLES.AGENT, ROLES.OPERATOR, ROLES.PERFORMER]
  }
];
